export const extractCategory = (fileName: string) =>
  fileName.substring(0, fileName.split(' ')[0].lastIndexOf('-'));

export const getDocumentNum = (fileName: string) =>
  fileName.split(' ')[0].split('-')[fileName.split(' ')[0].split('-').length - 1];

export const getDocumentTitle = (fileName: string) =>
  fileName.substring(0, fileName.lastIndexOf('.'));

export const removeNullAttributes = (obj: Record<string, string>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v.length > 0));
};

export const getDate = (originalDate: string): string => {
  const dateObject = new Date(originalDate);
  const formattedDate = dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formattedDate;
};
export const getDateTime = (originalDate: string): string => {
  const formattedDate = getDate(originalDate);

  return `${formattedDate}`;
};
