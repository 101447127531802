import {
  Button,
  Header,
  TableHeader,
  Input,
  Dropzone,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import { QueryClient } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useState, useMemo, useContext, ChangeEvent } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import SvgIcon from '../../../components/elements/SvgIcon';
import Layout from '../../../components/layout';
import { PaginatedTable } from '../../../components/PaginatedTable';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { notifyError } from '../../../helpers/utils';
import { useCatalog } from '../../../services/api';
import {
  ColumnType,
  InputProps,
  OptionValue,
  SourceFile,
  SourceFileGroup,
} from './UploadSource.type';
import { tableValueFormatter } from '../../../helpers/tableFormatter';
import { DocumentTabs } from '../../../types';
import useModal from '../../../hooks/useModal';
import { ItemType } from '../../../services/api/types';
import { getAllCatalogItems } from '../../settings/components/utils/apiHelpers';
import CatalogItemSlideOver from '../../../components/CatalogItemSlideOver';
import { createIsLinkActive } from '../../../helpers/createIsActiveLink';
import CheckboxSelectionSlideOver from '../../../components/CheckboxSelectionSlideOver/CheckboxSelectionSlideOver';

const MAX_FILES = 20;

const UploadSource = () => {
  const { pathname } = useLocation();
  const [files, setFiles] = useState<SourceFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setCurrentPerPage] = useState(10);
  const [currentFile, setCurrentFile] = useState<SourceFile>();
  const catalogItemSlideOver = useModal();
  const [selectedSiteOptions, setSelectedSiteOptions] = useState<OptionValue[]>([]);

  const isLinkActive = createIsLinkActive(pathname);

  const handleCloseCreateItem = catalogItemSlideOver.closeModal;

  const siteSlideOver = useModal();

  const { data: groups } = useQuery({
    queryKey: [ItemType.Group],
    queryFn: () => getAllCatalogItems(ItemType.Group),
  });

  const breadcrumbItems = [
    { label: 'Catalog', to: '/Catalog' },
    { label: 'Upload Sources', to: pathname, active: true },
  ];

  const { setCatalogFilesUploadStatus } = useContext(CatalogContext);
  const navigate = useNavigate();
  const columnsHelper = createColumnHelper<ColumnType>();

  const { data: sitesResponse } = useQuery({
    queryKey: [ItemType.Site],
    queryFn: () => getAllCatalogItems(ItemType.Site),
  });

  const siteOptions = useMemo(
    () =>
      (sitesResponse || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [sitesResponse],
  );

  const handleSiteSave = (selectedValues: OptionValue[]) => {
    if (currentFile) {
      const selectedLabels = selectedValues.map((option) => option.label);
      const newSite = selectedLabels.join(', ');
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.map((file) =>
          file.id === currentFile.id ? { ...file, site: newSite } : file,
        );

        return updatedFiles;
      });

      siteSlideOver.closeModal();
    }
  };

  const handleSiteModalClick = (file: SourceFile) => {
    setCurrentFile(file);

    if (!file) {
      setSelectedSiteOptions([]);
      return;
    }
    const currentSites = file.site ? file.site.split(', ') : [];
    const selectedOptions = siteOptions.filter((option) => currentSites.includes(option.label));
    setSelectedSiteOptions(selectedOptions);
    siteSlideOver.openModal();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, info: CellContext<ColumnType, any>) => {
    const { name, value } = e.target;
    const fileId = info.row.original.id;

    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === fileId) {
          return { ...file, [name]: value };
        }
        return file;
      });
    });
  };

  const handleGroupModalClick = (tempSelectedItems: SourceFileGroup[]) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file === currentFile) {
          return { ...file, group: tempSelectedItems };
        }
        return file;
      }),
    );
    handleCloseCreateItem();
  };

  const getColumn = ({ name, title, required = false, size = 50, type = 'text' }: InputProps) => ({
    header: () => (
      <TableHeader handleColumnSort={() => {}} Title={required ? `${title}*` : title} />
    ),
    cell: (info: CellContext<ColumnType, any>) => (
      <Input
        id={info.row.original.id}
        className="invalid:border-negative-200"
        key={info.row.original.id}
        value={tableValueFormatter(info)}
        step={0.1}
        min={0.1}
        type={type}
        readOnly={name === 'group' || name === 'site'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, info)}
        onClick={() => {
          if (name === 'group') {
            setCurrentFile(info.row.original as SourceFile);
            catalogItemSlideOver.openModal();
          }
          if (name === 'site') {
            handleSiteModalClick(info.row.original as SourceFile);
          }
        }}
        name={name}
        required={required}
      />
    ),
    size: size,
  });

  const columns = useMemo(
    () => [
      columnsHelper.accessor(
        'title',
        getColumn({ name: 'title', title: 'Name', required: true, size: 200 }),
      ),
      columnsHelper.accessor(
        'version',
        getColumn({ name: 'version', title: 'Version', required: true, type: 'number' }),
      ),
      columnsHelper.accessor('group', getColumn({ name: 'group', title: 'Group', required: true })),
      columnsHelper.accessor('site', getColumn({ name: 'site', title: 'Site' })),
      columnsHelper.accessor('sourceType', getColumn({ name: 'sourceType', title: 'Source Type' })),
      columnsHelper.accessor('id', {
        header: () => <TableHeader handleColumnSort={() => {}} Title="" />,
        cell: (info: CellContext<ColumnType, any>) => (
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => handleDelete(info.row.original.id)}>
            <SvgIcon iconType="delete" />
          </div>
        ),
      }),
    ],
    [siteOptions],
  );

  const handleOnDrop = (newFiles: File[]) => {
    if (newFiles.length > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      return;
    }
    const updatedFiles: SourceFile[] = newFiles.map((file) => ({
      id: v4(),
      title: file.name,
      version: 1.0,
      sourceType: 'source',
      group: [],
      site: '',
      file: file,
    }));
    const totalFiles = updatedFiles.length + files.length;
    if (totalFiles > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      setFiles((prev) => [...prev, ...updatedFiles].slice(0, MAX_FILES));
    } else {
      setFiles((prev) => [...prev, ...updatedFiles]);
    }
  };

  const handleSelectAll = (allChecked: boolean, files: ColumnType[]) => {
    if (!allChecked) {
      setSelectedFiles(files.map((file) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleOnSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedFiles([...selectedFiles, value]);
    } else {
      setSelectedFiles(selectedFiles.filter((id) => id !== value));
    }
  };

  const validFiles = useMemo(
    () =>
      files.length > 0 &&
      files.every(
        ({ title, version, group }) =>
          title &&
          version &&
          version < 1000 &&
          (version.toString().includes('.')
            ? version.toString()?.split('.')[1]?.length <= 2
            : true) &&
          group.length,
      ),
    [files],
  );

  const queryClient = new QueryClient();
  const { addToCatalog } = useCatalog();

  const handleOnSubmit = () => {
    setCatalogFilesUploadStatus((prev) => prev.filter((status) => status.status === 'pending'));
    const promises = files.map((file) => {
      setCatalogFilesUploadStatus((prev) => [
        ...prev,
        { id: file.id, status: 'pending', title: file.title },
      ]);
      const formData = new FormData();
      for (const key in file) {
        const value = file[key as keyof SourceFile];
        if (key === 'group') {
          formData.append('groupIds', JSON.stringify(file[key].map((item) => item.id)));
        } else {
          formData.append(key, value as string | Blob);
        }
      }
      return addToCatalog
        .mutateAsync(formData)
        .then(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) =>
              status.id === file.id ? { ...status, status: 'success' } : status,
            ),
          );
        })
        .catch(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) => (status.id === file.id ? { ...status, status: 'error' } : status)),
          );
        });
    });
    Promise.all(promises).then(() => {
      queryClient.invalidateQueries({ queryKey: ['Catalog'] });
    });
    navigateToSourceDocuments();
  };

  const navigateToSourceDocuments = () => {
    navigate('/list', {
      state: {
        activeTab: DocumentTabs.SOURCE,
      },
    });
  };

  const handleAddGroups = (groups: SourceFileGroup[]) => {
    setCurrentFile({
      ...currentFile,
      group: [...groups],
    } as SourceFile);
    handleGroupModalClick(groups);
  };

  const handleResetSelection = () => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file === currentFile) {
          return { ...file, group: [] };
        }
        return file;
      }),
    );
    setCurrentFile({
      ...currentFile,
      group: [],
    } as SourceFile);
  };

  const handleDelete = (id: string) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
    setSelectedFiles((prev) => prev.filter((fileId) => fileId !== id));
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <Header breadcrumbItems={breadcrumbItems} isLinkActive={isLinkActive}>
            <Header.Heading>Upload Documents</Header.Heading>
            <Header.Actions>
              <Button variant="secondary" disabled={!validFiles} onClick={handleOnSubmit}>
                Add to Catalog
              </Button>
              <Button variant="secondary" onClick={navigateToSourceDocuments}>
                Cancel
              </Button>
            </Header.Actions>
          </Header>
        </Layout.Header>
        <Layout.Body>
          <div className="gap-[1.5rem] flex flex-col items-start flex-1 w-full">
            <div className="flex justify-between items-center self-stretch">
              <p className="text-primary-300">Upload a maximum of {MAX_FILES} files at a time</p>
              <p className="text-primary-200 font-normal text-xs">
                Items Marked with a * are required
              </p>
            </div>
            <div className="gap-[1rem] flex flex-col items-start flex-1 w-full">
              <div className="w-full h-[5.32rem]">
                <Dropzone maxFiles={MAX_FILES} onDrop={handleOnDrop} />
              </div>
              {files.length === 0 ? (
                <div className="w-full h-full flex items-center justify-center bg-white border rounded-md">
                  <p className="text-gray-500">No uploaded documents</p>
                </div>
              ) : (
                <PaginatedTable<ColumnType>
                  additionalColumns={columns}
                  menuItems={[]}
                  paginatedFilteredData={{
                    pagination: { page: currentPage, perPage: currentPerPage },
                  }}
                  getData={(() => {}) as any}
                  clientSideData={files}
                  clientSidePagination={true}
                  handleSelectAll={handleSelectAll}
                  onSelect={handleOnSelect}
                  selectedData={selectedFiles}
                  handlePageChange={setCurrentPage}
                  handlePerPageChange={setCurrentPerPage}
                />
              )}
              {catalogItemSlideOver.show && (
                <CatalogItemSlideOver
                  onClose={handleCloseCreateItem}
                  isOpen={catalogItemSlideOver.show}
                  heading="Assign Groups"
                  catalogItems={groups || []}
                  selectedItems={currentFile?.group || []}
                  handleResetSelection={handleResetSelection}
                  handleAdd={handleAddGroups}
                />
              )}
              <CheckboxSelectionSlideOver
                isOpen={siteSlideOver.show}
                handleClose={siteSlideOver.closeModal}
                onSave={handleSiteSave}
                options={siteOptions}
                selectedOptions={selectedSiteOptions}
                title="Select Site"
                displayBackButton
              />
            </div>
          </div>
        </Layout.Body>
      </Layout>
    </>
  );
};

export default UploadSource;
