import { Editor } from '@tinymce/tinymce-react';
import { Dispatch, SetStateAction, RefObject, Component } from 'react';
import { CatalogFilesUploadStatus } from './CatalogTypes';
import { CatalogItemsHash } from '../pages/upload';
import { CatalogItems, Projects } from '../services/api/types';
export type statuses = 'pending' | 'verified' | 'rejected' | '';

export interface TargetSession {
  createdAt: string;
  documentId: string;
  key: string;
  numbering: number;
  title: string;
  verficationStatus: statuses;
  scoreStatus?: statuses;
  id: number;
}
export interface SourceNode {
  nodeId: string;
  fileId: number;
  value: string;
  id: number;
  approved: boolean;
  title?: string;
}

export interface IQuickFillModal {
  closeQuickFillModal: () => void;
  quickFillModalOpen: boolean;
  gvCategories: ICategory[];
  clearSelection: () => void;
  selectedOption: string | null;
  handleOptionChange: (option: string) => void;
  handleApplyFilter: (filterValue: string) => void;
  isLoading: boolean;
}

export interface IExpandableSection {
  title: string;
  options: string[];
  selectedOption: string | null;
  handleOptionChange: (option: string) => void;
  defaultOpen?: boolean;
}

export interface TargetInterface {
  id: number;
  documentList: TargetSession[];
  isSessionLoaded: boolean;
  getAllUploadedDocs: jest.Mock;
  title: string;
  showLoader: boolean;
  setShowLoader: Dispatch<SetStateAction<boolean>>;
  handleRefresh: () => void;
  setDeleteId: Dispatch<SetStateAction<number>>;
  filename: string;
  setComponentPaginationData: Dispatch<SetStateAction<object>>;
  componentPaginationData: { page: number; perPage: number; total?: number };
  setPageChanged: Dispatch<SetStateAction<boolean>>;
  totalRecords: number;
}

export interface TargetMapping {
  createdAt: string;
  documentId?: string;
  creatorname: string;
  mappingId?: number | string;
  mappingNumber: number;
  mode: string;
  originalName: string;
  score?: string | null;
  status?: statuses;
  statusupdatername?: string;
  suggestionId?: number | undefined;
  targetNodeId?: string | undefined;
  sourceNodeId?: string | undefined;
}

export interface TargetMappingResponse {
  data: TargetMapping[];
}

export interface IUserInfoContext {
  permissions: any;
  firstName: string;
  lastName: string;
  userId: string;
  isViewChanged: boolean;
  setIsViewChanged: Dispatch<SetStateAction<boolean>>;
}

export interface IMapComment {
  firstName: string;
  lastName: string;
  comment: string;
  commentNumber: number;
  mappingNumber: number;
  targetNodeId: string;
}

export interface IMappingContext {
  sourceFileDoc: any;
  setSourceFileDoc: Dispatch<SetStateAction<any>>;
  currentMapping?: TargetMapping;
  setCurrentMapping: Dispatch<SetStateAction<TargetMapping | undefined>>;
  finishedScoring: boolean;
  setFinishedScoring: Dispatch<SetStateAction<boolean>>;
  sessionMappings: TargetMapping[] | undefined;
  setSessionMappings: Dispatch<SetStateAction<TargetMapping[] | undefined>>;
  mappingSession: TargetSession | undefined;
  setMappingSession: Dispatch<SetStateAction<TargetSession | undefined>>;
  showLoader: boolean;
  setShowLoader: Dispatch<SetStateAction<boolean>>;
  editor1Content: any;
  setEditor1Content: Dispatch<SetStateAction<any>>;
  mappingComments: IMapComment[];
  setMappingComments: Dispatch<SetStateAction<IMapComment[]>>;
  isCommentDisplayed: boolean;
  setIsCommentDisplayed: Dispatch<SetStateAction<boolean>>;
  commentNumber: number;
  setCommentNumber: Dispatch<SetStateAction<number>>;
  editor1Ref: any;
  editor2Ref: any;
  setDocStates: () => void;
  markMappingDone: (key: string, value?: string) => void;
  fetchTargetFile: (docId: string) => void;
  resetStates: () => void;
  toggleComments: () => void;
  handleSuperscript: (
    name?: string,
    number?: number,
    mappingNumber?: number | undefined,
    targetNodeId?: string | undefined,
  ) => void;
  isPreviousAvailable: () => boolean;
  isNextAvailable: () => boolean;
  handleNavigate: (type: string) => TargetMapping | undefined;
  targetId: number;
  setTargetId: Dispatch<SetStateAction<number>>;
  getElements: (editorRef: any, query: any) => any;
  addCommentBubble: (mappingNumber?: number | undefined, targetNodeId?: string | undefined) => void;
}

export type GeneralizationDocument = {
  id: number;
  html: string;
  filename: string;
  nonParsedCatalogId?: number;
  version?: number;
};

export enum AcceptanceMethodEnum {
  AUTHOR = 'author',
  MANUAL = 'manual',
  QUICK_FILL = 'quick fill',
  AUTO_FILL = 'auto fill',
  AUTO_SAVE = 'auto save',
}

export type GeneralizationSuggestion = {
  sourceValue: string;
  id: number;
  targetNodeId: string;
  sourceNodeId: string;
  status: string;
  createdAt: string;
  approvedBy?: User;
  mappedBy?: User;
  mapperType?: string;
  targetValue: string;
  order?: number;
  sourceFileId: number;
  targetFileId: number;
  sourceFile: { id: number };
  oldTargetValue?: string;
  previousSuggestionId?: number;
  positionX?: number;
  positionY?: number;
  selected: boolean;
  acceptanceMethod?: AcceptanceMethodEnum;
};

export interface ICategory {
  title: string;
  options: string[];
}

export interface IGroupingVariables {
  [key: string]: string[];
}

// TODO: update any type
export type AIResponse = {
  [key: string]: Array<{ [key: string]: any }>;
};

export type User = {
  firstName: string;
  lastName: string;
};

export interface IFilterItem {
  key: string | number;
  value: string | number;
  text?: string;
}

export type FormattedOptions = {
  [key: string]: IFilterItem[];
};

export interface IGeneralizationContext {
  gvCategories: ICategory[];
  setGVCategories: Dispatch<SetStateAction<ICategory[]>>;
  sessionId: number | undefined;
  loadingGeneratedDocument: boolean;
  aiResponse: AIResponse | undefined;
  acceptedSuggestions: GeneralizationSuggestion[] | undefined;
  generatedDocument: GeneralizationDocument | undefined;
  sourceDocuments: Map<string, GeneralizationDocument> | undefined;
  setAcceptedSuggestions: Dispatch<SetStateAction<GeneralizationSuggestion[] | undefined>>;
  getSourceNodeIndex: (
    targetNodeId: string,
    aiRes?: AIResponse | undefined,
    sourceNodeId?: string | null,
  ) => number;
  setAiResponse: Dispatch<SetStateAction<AIResponse | undefined>>;
  targetNodeIdSuggestionMap: GeneralizationSuggestionMap;
  sourceNodeIdSuggestionMap: GeneralizationSuggestionMap;
  setGeneratedDocumentWithAcceptedSuggestions: (doc: Document) => void;
  selectedCatalogId: number;
  setLoadingSourceDocument: Dispatch<SetStateAction<boolean>>;
  loadingSourceDocument: boolean;
  setSelectedCatalogId: (id: number) => void;
  setSourceDocuments: React.Dispatch<
    React.SetStateAction<Map<string, GeneralizationDocument> | undefined>
  >;
  handleSourcechange: (value: string, getParsed?: boolean) => void;
  handleAddSourceFiles: () => void;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  openSourceModal: () => void;
  closeSourceModal: () => void;
  sourceModalOpen: boolean;
  setSourceModalOpen: Dispatch<SetStateAction<boolean>>;
  handleGetSourceContent: (id: number) => void;
  lastSaved: Date | undefined;
  setLastSaved: Dispatch<SetStateAction<Date | undefined>>;
  authors: string[];
  validators: string[];
  setValidators: Dispatch<SetStateAction<string[]>>;
  mappingType: MappingTypeEnum;
  mappingSessionTitle: string;
  selectionSearch: boolean;
  setSelectionSearch: Dispatch<SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  aiResponseCopy: AIResponse | undefined;
  setAiResponseCopy: Dispatch<SetStateAction<AIResponse | undefined>>;
  clearAISuggestions: boolean;
  setClearAISuggestions: Dispatch<SetStateAction<boolean>>;
  selectedSourceFileName: string;
  setSelectedSourceFileName: Dispatch<SetStateAction<string>>;
  cells: string[];
  setCells: Dispatch<SetStateAction<string[]>>;
  lockSourceFile: boolean;
  setLockSourceFile: Dispatch<SetStateAction<boolean>>;
  isParsed: boolean;
  groupingVariables: string[];
  setGroupingVariables: Dispatch<SetStateAction<string[]>>;
  resetStates: () => void;
  isOriginalAvailable: boolean;
  sanitizeAiResponse: (response: AIResponse) => AIResponse;
  isFullScreen: boolean;
  setIsFullScreen: (fullScreen: boolean | ((prev: boolean) => boolean)) => void;
  setIsAutoSave: Dispatch<SetStateAction<boolean>>;
  isAutoSave: boolean;
  initialTarget: Document | undefined;
  setInitialTarget: Dispatch<SetStateAction<Document | undefined>>;
}

export interface ISourceModalStates {
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  sourceFiles: any;
  setSourceFiles: Dispatch<SetStateAction<any>>;
  refreshSourceFiles: boolean;
  setRefreshSourceFiles: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<Filter>>;
  isLoading: boolean;
}

export interface IApiResponse {
  data: any;
  status: number;
  statusText: string;
}

export enum TargetSessionStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  NONE = '',
}

export enum TargetSessionTypesEnum {
  DOCUMENTS_VERIFICATION = 'documents-verification',
  DOCUMENTS_SCORING = 'documents-scoring',
  MAPPING_VERIFICATION = 'mapping-verification',
  SCORING = 'scoring',
  REWRITE_DOCUMENT = 'rewrite-document',
}

export interface INavItem {
  permission: string;
  text?: string;
  icon?: string;
  className?: string;
  shortText?: string;
}

export interface IActiveUser {
  firstName: string;
  lastName: string;
  permissions: string[];
  role: string;
  roles: string[];
  token: string;
  userId: string;
  photo?: string;
}

export interface INavItem {
  permission: string;
  text?: string;
  icon?: string;
  className?: string;
  shortText?: string;
}

export interface IRoles {
  name: string;
}

export interface IErrors {
  firstName: string;
  lastName: string;
  email: string;
  roles: string;
}

export interface IUserData {
  id?: number | string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  roles?: IRoles[];
  avatar?: File;
}

export enum TargetSessionTitles {
  manually_authored_docs = 'Manually Authored Documents',
  autowritten_docs = 'Autowritten Documents',
}

export enum userActions {
  createAction = 'Create',
  editAction = 'Edit',
}

export interface IUserObj {
  userId: string;
  name: string;
  email: string;
  avatar: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export type Catalog = {
  id: string;
  title: string;
  version: number;
  substanceProdCat: string;
  site: string;
  program: string;
  documentType: string;
  documentDrugCat: string;
  company: string;
  documentName: string;
  documentCat: string;
  documentNum: string;
  documentSubCat: string;
  documentSource: string;
  methodOrScale: string;
  project?: string;
  group?: string;
  sourceType?: string;
  originalName?: string;
  value?: string;
};

type CatalogDistinctValues = {
  uploadedBy: { key: string; value: string }[];
  programs: { key: string; value: string }[];
  documentTypes: { key: string; value: string }[];
  documentCategories: { key: string; value: string }[];
};

export interface ICatalogContext {
  sourceFiles: any;
  setSourceFiles: Dispatch<SetStateAction<any>>;
  isLoading: boolean;
  distinctValues: CatalogDistinctValues;
  setRefreshSourceFiles: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<Filter>>;
  getUserName: (id: number) => string | undefined;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  resetPagination: () => void;
  refreshCatalogFiles: (documentType?: SourceDocumentTypeEnum) => void;
  selectedSourceDocuments: any;
  setSelectedSourceDocuments: Dispatch<SetStateAction<number[]>>;
  selectedTargetDocument: any | null;
  setSelectedTargetDocument: Dispatch<SetStateAction<any | null>>;
  refreshDistinctValues: (documentType?: SourceDocumentTypeEnum) => void;
  dropdownUsersData: IDropdown[];
  setDropdownUsersData: Dispatch<SetStateAction<IDropdown[]>>;
  dropdownProgramsData: IDropdown[];
  setDropdownProgramsData: Dispatch<SetStateAction<IDropdown[]>>;
  dropdownDocCatData: IDropdown[];
  setDropdownDocCatData: Dispatch<SetStateAction<IDropdown[]>>;
  catalogFilesUploadStatus: CatalogFilesUploadStatus[];
  setCatalogFilesUploadStatus: Dispatch<SetStateAction<CatalogFilesUploadStatus[]>>;
}

export type IDropdown = {
  selected: boolean;
  text: string;
  value: string;
};

export type Pagination = {
  page: number;
  perPage: number;
  total?: number;
};

export type Filter = {
  [key: string]: string | string[];
};

export interface IDistinctValues {
  uploadedBy: {
    key: string;
    value: string;
  }[];
  programs: {
    key: string;
    value: string;
  }[];
  documentTypes: {
    key: string;
    value: string;
  }[];
}

export interface ISourceDocument {
  id: number;
  nonParsedCatalogId: number;
  documentType: string;
  title: string;
  controlledId: string;
  version: number;
  substanceProdCat: string;
  documentStatus: string;
  documentDrugCat: string;
  program: string;
  site: string;
  company: string;
  documentCat: string;
  documentNum: string;
  documentName: string;
  documentSubCat: string;
  documentAuthor: string;
  documentApprover: string;
  documentSource: string;
  methodOrScale: string;
  documentSet?: string;
  lastModified?: string;
  approvalDate?: string;
  releaseDate?: string;
  uploadedBy?: string;
  deletedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  user: IUser;
  isInteractive: boolean;
  catalogItems?: CatalogItemsHash;
  project?: string;
  ctdSection?: string;
  section?: string;
  templateType?: string;
  createdBy: { firstName: string; lastName: string };
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  confirmedAt: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}
export type EditorRef =
  | (React.MutableRefObject<Editor & { getDoc: () => Document }> & {
      getDoc: () => Document;
      getBody: () => Document | HTMLBodyElement;
    })
  | null;

export type GetElementFn = <T extends Element = Element>(
  editorRef: React.MutableRefObject<any>,
  nodeId: string,
) => T | null;

export interface ICatalogFilters {
  documentType?: string;
  text?: string;
  updatedBy?: string;
  uploadedBy?: string;
  program?: string;
  createdById?: string;
  columnSort?: string;
  sortType?: string;
  documentCat?: string;
  searchQuery?: string;
  activeTab?: string;
}
export interface ISourceDocsRecord {
  id: number;
  title: string;
  uploadedBy: string;
  documentType: string;
  program: string;
}

export interface ISuggestionMapper {
  type: 'author' | 'verifier' | 'default';
  color: string;
  label: string;
}

export interface IHandleAcceptedSuggestionParams {
  targetNodeId: () => string;
  clearSuggestions: () => void;
  highlightTargetSuggestion: (color: string, label: string) => void;
  highlightSourceSuggestion: (color: string, label: string) => void;
  editor2Ref: React.RefObject<any>;
}

export type ObjectValue = { [key: string]: { value: string; sourceFileId: number } };

export interface ITargetDocument {
  createdAt: string;
  deletedAt: string;
  documentId: string;
  id: number;
  title: string;
  documentTitle: string;
  updatedAt: string;
  ctdSection: string;
  contentSpecifics: string;
  program: string;
  updatedBy: any;
  version: number;
  documentType: SourceDocumentType;
}
export type SourceDocumentType = 'Target' | 'Source';

export enum SourceDocumentTypeEnum {
  Target = 'Target',
  Source = 'Source',
  Pre_Authored = 'Pre-Authored',
}

export interface IResetPasswordResponse {
  status: number;
  response: {
    message: string;
  };
}

export interface IResetPasswordProps {
  forReset: boolean;
}

export interface BreadCrumbData {
  link?: string;
  text: string;
  state?: { activeTab?: string };
  clickable?: boolean;
  color?: string;
}

export interface IBreadCrumb {
  className: string;
  data: BreadCrumbData[];
}

export type DocumentOption = {
  value: string;
  text: string;
};

export type CatalogSourceNodeObject = {
  [key: string]: string[];
};

export type SuggestionData = {
  sessionId: number | undefined;
  targetNodeId: string;
  sourceNodeId?: string | undefined;
  value?: string | null | undefined;
  previousSuggestionId?: number | undefined;
  order?: number | undefined;
  documentId: string | null | undefined;
  mapperType?: string | undefined;
};

export type GeneralizationUndoState = string[];

export interface IAutoMappedDocument {
  id: number;
  title: string;
  status: string;
  createdBy: { firstName: string; lastName: string };
}

export interface IGeneralizedDocument {
  [key: string]: [string, string];
  id: [string, string];
  title: [string, string];
  catalogIds: [string, string];
  totalSuggestionsCount: [string, string];
  status: [string, string];
  createdBy: [string, string];
  Actions: [string, string];
}

export interface GeneralizationSuggestionWithColor extends GeneralizationSuggestion {
  color: string;
}

export type FilterType = 'targetNodeId' | 'sourceNodeId';

export interface HighlightObject {
  id: string;
  color?: string;
}
export interface ICSVLinkProps {
  data: any[];
  headers?: any[];
  ref?: RefObject<any>;
  filename?: string;
  [key: string]: any;
}

export class CSVLink extends Component<ICSVLinkProps> {}

export interface ISmartCatalogFile {
  id: string;
  name: string;
  updated: string;
  size: number;
}

export interface IExportCSVProps {
  exportData?: () => void;
  csvInstance: RefObject<any>;
  data: any[];
  headers: any[];
  title: string;
  styles?: string;
  showButton?: boolean;
  filename?: string;
  width?: string;
  loader?: boolean;
}

export interface IMappingData {
  tableNumber: number;
  tableColumn: number;
  tableRow: number;
  value: string;
  dvStatus: string;
  suggestedChange: string;
  sourceDocNumber: string;
  sourceTitle: string;
  sourceVersion: number;
  notes: string;
}

export interface IDVeditModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  mappingData: IMappingData;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export interface IAutoMappingModalProps {
  isOpen: boolean;
  closeModal: () => void;
  preAuthoredDocumentId: number | null;
  sourceFileIds: number[];
}

export interface IFileNameModalProps {
  finish: (name: string) => void;
  id: string;
  isOpen: boolean;
  closeModal: () => void;
  title: string;
}

export interface ModalHeaderProps {
  title: string;
  closeModal?: () => void;
  parentBoxStyle?: string;
}

export interface IUseGeneralizationComments {
  openCommentModal: (suggestionId: string) => void;
  isCommentDisplayed: boolean;
  toggleComments: () => void;
  commentModal: JSX.Element | null;
  openCommentModalWithThread: (id: string) => void;
}

export interface IRejectionNotes {
  notes: string;
  change: string;
  suggestedChange: string;
  sourceDocNumber: string;
}

export type AutoMappingLoading = {
  isMappingSessionsLoading: boolean;
  isCatalogDocLoading: boolean;
};

export interface IAutoMappingContext {
  currentTargetNodeId: number;
  setCurrentTargetNodeId: Dispatch<SetStateAction<number>>;
  currentSourceNodeId: string;
  setCurrentSourceNodeId: Dispatch<SetStateAction<string>>;
  scrollTarget: () => void;
  handleApproveState: (state: boolean) => void;
  sourceContent: sourceContent;
  setSourceContent: Dispatch<SetStateAction<sourceContent>>;
  handleHighlight: () => void;
  handleSuggestionClick: (element: SourceNode, index: number) => void;
  handleSuggestionSelected: (element: SourceNode, index: number) => void;
  customTextOnSelect: (node: JSX.Element) => string;
  navigateMapping: (direction: 'next' | 'previous') => void;
  navigateSuggestions: (direction: 'next' | 'previous') => void;
  isNextAvailable: () => void;
  isPreviousAvailable: () => void;
  handleApprove: (paramsId: number) => void;
  hasNoResponse: boolean;
  setHasNoResponse: Dispatch<SetStateAction<boolean>>;
  isLoading: AutoMappingLoading;
  setIsLoading: Dispatch<SetStateAction<AutoMappingLoading>>;
  selectedSuggestion: string;
  setSelectedSuggestion: Dispatch<SetStateAction<string>>;
  isDisabledApprove: boolean;
  setIsDisabledApprove: Dispatch<SetStateAction<boolean>>;
  resetPlaceholder: boolean;
  setResetPlaceholder: Dispatch<SetStateAction<boolean>>;
  approveDisable: boolean;
  setApproveDisable: Dispatch<SetStateAction<boolean>>;
  autoMappedId: number;
  setAutoMappedId: Dispatch<SetStateAction<number>>;
  allSourceFiles: IAllSourceFiles[];
  setAllSourceFiles: Dispatch<SetStateAction<IAllSourceFiles[]>>;
  checkApprove: boolean;
  setCheckApprove: Dispatch<SetStateAction<boolean>>;
  falseMappedId: number | undefined;
  setFalseMappedId: Dispatch<SetStateAction<number | undefined>>;
  activeSuggestion: string;
  setActiveSuggestion: Dispatch<SetStateAction<string>>;
  activeSuggestionId: number | undefined;
  setActiveSuggestionId: Dispatch<SetStateAction<number | undefined>>;
  activeSuggestionIndex: number | undefined;
  setActiveSuggestionIndex: Dispatch<SetStateAction<number | undefined>>;
  editorModal: boolean;
  setEditorModal: Dispatch<SetStateAction<boolean>>;
  sourceId: number;
  setSourceId: Dispatch<SetStateAction<number>>;
  targetFileContent: TargetContent;
  setTargetFileContent: Dispatch<SetStateAction<TargetContent>>;
  mappingSuggestions: mappingSuggestions;
  setMappingSuggestions: Dispatch<SetStateAction<mappingSuggestions>>;
  currentNodeSuggestionFiles: () => void;
  handleGetSource: () => void;
  editor1Ref: any;
  editor2Ref: any;
  isNextSuggestionAvailable: () => void;
  isPreviousSuggestionAvailable: () => void;
  suggestionIndex: number;
  setSuggestionIndex: Dispatch<SetStateAction<number>>;
  handleSearchTerm: (term: any) => void;
  searchResults: any;
  setSearchResults: Dispatch<SetStateAction<any[]>>;
  sessionParamsId: number;
  setSessionParamsId: Dispatch<SetStateAction<number>>;
}

export type mappingSuggestions = {
  sourceFileNodes: SourceNode[];
  mappingId: string;
}[];

export type TargetContent = {
  title: string;
  html: string;
};

export type sourceContent = {
  filename: string;
  html: string;
  id: number;
};

export interface IAllSourceFiles {
  id: number;
  title: string;
}

export interface IDVFormState {
  fileName: string;
  tables: number[];
  version: number;
  sourceTables: number[];
}

export type SetStateFunction<T> = (prevState: T | undefined) => T;

export type DateFormatOptions = {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZone?: string;
};

export interface KeyboardEventWithShift extends KeyboardEvent {
  shiftKey: boolean;
}

export type IAutoMappedCompletedDocument = {
  id: number;
  documentTitle: string;
  status: string;
  mappingType: string;
  username: string;
  ctdSection: string;
  contentSpecifics: string;
  program: string;
  createdBy: { firstName: string; lastName: string };
};

export type NodeProps = {
  children: Node[];
};

export type Node = {
  props?: NodeProps;
};

export type SearchOptions = {
  label: JSX.Element;
  value: number;
  nodeId?: string;
  order?: number;
};

export type AutowriteRequestData = {
  ctdSection: string;
  program: string;
  version: number;
  title: string;
  sourceIds: number[];
  automatedDocId: number;
  contentSpecifics?: string;
  detailedNotes?: string;
};

export type SearchResult = {
  catalog_id: string;
  fileTitle: string;
  dataText: string;
  dataNodeId: string;
  score: string;
};

export interface CatalogNotificationContextType {
  catalogNotifications: CatalogNotification[];
  addCatalogNotification: (notifications: CatalogNotification[]) => void;
  removeCatalogNotification: (id: number) => void;
  openCatalogNotificationModal: () => void;
  closeCatalogNotificationModal: () => void;
  setCatalogNotifications: React.Dispatch<React.SetStateAction<any[]>>;
}

export type CatalogNotification = {
  id: number;
  title: string;
  status: 'In Progress' | 'Uploaded Successfully' | 'Failed to Upload';
};

export interface CatalogNode {
  parentNodeId: string;
  tag: string;
  dataNodeId: string;
  siblingIdx: string;
  text?: string;
  attrNodeId: string;
  attr: {
    'data-nodeid': string;
    'data-docid': string;
    'data-nodeorder': string;
    [key: string]: string;
  };
}

export interface newNodesPayload {
  nodes: CatalogNode[];
  catalogId: number;
}

type DocumentNode = HTMLElement | null;

export interface EditorReference {
  current: {
    getDoc: () => {
      body: DocumentNode;
    };
  };
}

export type EditorEvent = {
  node: HTMLElement;
  command?: string;
};

export type NewSuggestion = {
  documentId: string;
  mapperType: 'author' | 'ai' | 'reviewer';
  sourceNodeId: string;
  sourceValue: string | undefined;
  targetNodeId: string;
  targetValue: string;
  sessionId: number;
  sourceFileId: string;
  oldTargetValue?: string;
  id: number | null;
  previousSuggestionId?: number;
  order?: number;
};

export type MarkerAttributes = {
  positionX: number | null;
  positionY: number | null;
  targetId: string;
};

export interface HighlightOptions {
  element: HTMLElement | null | undefined;
  color?: string;
  isScroll?: boolean;
  mapperType?: string;
}

export enum EditorTypeEnum {
  Target = 'Target',
  Source = 'Source',
}

export type EditorType = keyof typeof EditorTypeEnum;

export type GeneratedDocumentTabs = 'auto' | 'manual' | 'pre-authored' | 'automapping';

export type MappingSessionRecord = {
  id: number;
  title: string;
  documentTitle: string | null;
  status: string;
  version: number;
  ctdSection: string;
  program: string;
  detailedNotes: string;
  contentSpecifics: string;
  totalSuggestionsCount: number;
  createdAt: string;
  mappingType: string;
  section: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
  sourceFileIds: Array<number>;
  project?: Pick<Projects, 'id' | 'name'>;
  catalogItems: CatalogItemsHash;
};

export interface DocumentChange {
  old: string;
  updated: string;
}

export interface GeneralizationSuggestionMap {
  [key: string]: GeneralizationSuggestion;
}

export type CsvHeaderType = Array<{ label: string; key: string }>;

export type DvExportLoadingType = {
  detailRecord: boolean;
  finalizedRecord: boolean;
  dvFormRecord: boolean;
  notes: boolean;
};

export type DocumentExportLoadingType = {
  cleanDocument: boolean;
  annotatedDocument: boolean;
};

export enum ListTypeEnum {
  TABLE_OF_CONTENTS = 'TABLE OF CONTENTS',
  LIST_OF_TABLES = 'LIST OF TABLES',
  LIST_OF_FIGURES = 'LIST OF FIGURES',
}

export type CatalogFilterType = {
  createdById: string | string[];
  documentType: DocumentTabs | DocumentTabs[];
};

export type GeneralizationFilterType = {
  sortType: string;
  columnSort: string;
  createdById: string;
  text: string;
  activeTab: GeneratedDocumentTabs;
};

export type SourceModalFilterType = {
  documentType: 'Source';
  program: string;
  createdBy: string;
  text: string;
  columnSort: string;
  sortType: string;
};

export type AutomappedDocumentsFilterType = {
  createdById: string;
  columnSort: string;
  sortType: string;
  text: string;
};

export type UsersFilterType = {
  sortType: string;
  columnSort: string;
  text: string;
};

export type AutomappingScreenFilterType = {
  documentType: 'Source';
  columnSort: string;
  sortType: string;
  program: string;
  text: string;
  createdById: string;
  documentCat: string;
};

export const enum DocumentTabs {
  AUTOMATED = 'Automated',
  TARGET = 'Target',
  SOURCE = 'Source',
  PRE_AUTHORED = 'Pre-Authored',
  IN_PROGRESS = 'In-Progress',
  COMPLETED = 'Completed',
}

export type IdChangesMap = {
  [key: string]: {
    old: string;
    updated: string;
  };
};

export type InputItem = {
  id?: string;
  type: string;
  label: string;
  name: string;
  value: number | string;
  placeholder: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  tooltip?: string;
  options?: any;
  min?: number;
  pattern?: RegExp;
};

export type GeneratedDocumentColumns = {
  accessorKey: string;
  header: () => string | JSX.Element;
  cell: (info: any) => any;
};

export interface DBUser {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  roles: Array<{ id?: number; name: string }>;
  lastLogin?: string;
}

export type CaptionType = 'Table' | 'Figure';

export type MappingMetadataType = {
  targetNumber: number;
  templateName: string;
  creatorname: string | undefined;
  targetValue: string | null;
  originalName: string | undefined;
  targetLocation: string;
  sourceLocation: string;
  status: string;
  statusUpdaterName?: string;
  sourceDocNumber: number | undefined;
  sourceDocVersion: number | undefined;
  notes?: string;
  suggestedChange?: string;
};

export enum MappingTypeEnum {
  AUTHORING = 'authoring',
  AI_AUTHORING = 'ai-authoring',
  AUTO_MAPPING = 'auto-mapping',
}

export type SizeUnit = `${number}${'px' | 'vh' | 'vw' | 'em' | 'rem' | '%'}`;

export interface ISuggestionsFilterModalProps {
  show: boolean;
  handleClose: () => void;
  groupingVariables: string[];
  handleApplyFilter: (value: string) => void;
  handleResetFilters: () => void;
}

export type UseQuickFillParams = {
  currentTargetNodeIds: string[] | undefined;
  updatedAiResponse: AIResponse;
  getCurrentNodeId: (id: string, editorRef: EditorRef, aiResponse: AIResponse) => string;
  callSaveSuggestions: (
    suggestions: any,
    acceptanceMethodProp: AcceptanceMethodEnum,
    addToHistory?: boolean,
  ) => Promise<GeneralizationSuggestion[] | undefined>;
  setInitialTarget: React.Dispatch<Document>;
  editor1Ref: any;
  aiResponse: AIResponse | undefined;
  sessionId: number | undefined;
  targetNodeIdSuggestionMap: GeneralizationSuggestionMap;
};

export type ButtonTitleProps = {
  title: string;
  loadingText: string;
  isLoading: boolean;
};

export type IColorSet = Partial<Record<'_50' | '_100' | '_200' | '_300' | '_400', string>>;

export interface IClientStyling {
  landingTitle: string;
  thumbnail: string;
  logo: string;
  colors: Record<'primary' | 'system' | 'neutral' | 'positive' | 'negative' | 'warning', IColorSet>;
}

export interface ProjectSettings {
  id: number;
  createdAt: string;
  name: string;
  createdBy: { firstName: string; lastName: string };
  catalogItems: CatalogItems[];
}

export type Option = {
  title: string;
  value: string;
};
