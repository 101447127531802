import { z } from 'zod';
import { versionSchema } from '../../../addSource/utils/schema';

export const copyDocumentSchema = z.object({
  title: z.string().min(1),
  project: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Project must have at least one element' })
    .max(1, { message: 'Project must have at most one element' }),
  sections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Section must have at least one element' }),
  version: versionSchema,
});
