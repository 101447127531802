// React related imports
import { FC } from 'react';
import { Controller } from 'react-hook-form';

// Components
import { Input } from '@gloabal-regulatory-writing-consulting/gxt-components';
import InputError from '../../../../../components/inputError/InputError';

// Types
import { IAuthoringFields } from './types';

const StartAuthoringFields: FC<IAuthoringFields> = ({ control, errors }) => {
  return (
    <form className="flex flex-col items-start gap-6 w-[50rem]">
      <div className="flex flex-col items-start gap-2 self-stretch">
        <Controller
          name="documentName"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputSize={'small'}
              label={'Document Name*'}
              primary
              placeholder="Document Name"
              error={!!errors['documentName']?.message}
              helpText={<InputError errors={errors} field={'documentName'} />}
            />
          )}
        />
      </div>
      <div className="flex flex-col items-start gap-2 self-stretch w-1/2">
        <Controller
          name="version"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              inputType={'number'}
              min={0}
              step={0.1}
              inputSize={'small'}
              label={'Version*'}
              primary
              placeholder="0.0"
              error={!!errors['version']?.message}
              helpText={<InputError errors={errors} field={'version'} />}
            />
          )}
        />
      </div>
    </form>
  );
};

export default StartAuthoringFields;
