import { FC } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import TableSkeleton from './TableSkeleton';

const CENTERED_COLUMNS = ['id', 'Actions', 'Checkbox'];

interface ITable {
  data: any[];
  columns: any[];
  rowSelectionCallback?: (row: any) => void;
  isDataLoading?: boolean;
}

export const Table: FC<ITable> = ({
  data,
  columns,
  rowSelectionCallback,
  isDataLoading = false,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: rowSelectionCallback,
  });

  const isCenteredColumn = (id: string) => CENTERED_COLUMNS.includes(id);

  return (
    <table className="min-w-full border-gray-400 border-b-0">
      <thead className="rounded">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            style={{ top: '-1px' }}
            className="text-xs rounded-tl bg-primary_bg_color text-white sticky z-10">
            {headerGroup.headers.map((header, idx) => (
              <th
                key={header.id + idx}
                {...{
                  className: `${
                    (idx === 0 && 'rounded-tl') ||
                    (idx === headerGroup.headers.length - 1 && 'rounded-tr')
                  } text-xs bg-primary_bg_color sticky text-left p-2 text-white top-0 max-h-6 text-[16px] font-bold tracking-[0.019px] border-gray-400`,
                  colSpan: header.colSpan,
                  style: {
                    width: isCenteredColumn(header.id)
                      ? '16px'
                      : (header.getSize() || 'undefined') !== 120
                        ? header.getSize()
                        : 'undefined',
                    textAlign: isCenteredColumn(header.id) ? 'center' : 'left',
                  },
                }}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `absolute top-0 right-0 h-full w-[1px] bg-gray-400 cursor-col-resize select-none touch-action-none resizer ${
                      header.column.getIsResizing() ? 'isResizing' : ''
                    }`,
                    style: {
                      transform: header.column.getIsResizing()
                        ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                        : '',
                    },
                  }}
                />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="table-header-group">
        {isDataLoading ? (
          <TableSkeleton columns={columns} />
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.original.id}
              className="odd:bg-[#F9FAFB] rounded-md p-1 py-2 w-full table-row">
              {row.getVisibleCells().map((cell, idx) => (
                <td
                  className="h-6 text-gray-600 border-b border-gray-400"
                  key={cell.id + idx}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '150%',
                    letterSpacing: '0.019px',
                    padding: '4px 8px',
                    height: '24px',
                    borderRight:
                      idx === row.getVisibleCells().length - 1
                        ? 'none'
                        : '1px solid var(--neutral-200, #9CA3AF)',
                    width: isCenteredColumn(cell.column.id) ? '16px' : cell.column.getSize(),
                    maxWidth: '400px',
                    textAlign: isCenteredColumn(cell.column.id) ? 'center' : 'left',
                  }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
